<template>
  <div>
    <b-row style="margin-bottom: 20px;">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" md="8">
        <strong style="font-size: 30px; font-family: cursive;">{{ d_pageInfoData.page_caption }}</strong>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <b-row v-if="d_pageInfoData">
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <template v-for="(page_info, page_info_index) in d_pageInfoData.page_info">
          <page-info :p_pageInfo="page_info"></page-info>
          <hr>
        </template>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
    <!-- {{ d_reports }} -->
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="10">
        <img v-if="d_loading.status" src="@/icon/sandwatch.gif" style="width: 2em;" />
        <template v-for="report_type in d_reports.paramorder">
          <b-row>
            <b-col sm="12" lg="12">
              <strong>{{ d_reports.parameters[report_type].label }}</strong>
            </b-col>
          </b-row>
          <b-row style="margin: 5px;">
            <b-col sm="12" lg="12">
              <template v-for="report_type_sub in d_reports.parameters[report_type].report_list.paramorder">
                <template v-if="d_reports.parameters[report_type].report_list.parameters[report_type_sub].report_list.length > 0">
                  <analyze-report-view :key="report_type + '_' + report_type_sub" :p_analyzeReports="d_reports.parameters[report_type].report_list.parameters[report_type_sub].report_list"></analyze-report-view>
                </template>
              </template>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import OrganizationService from '@/services/organization';
import AnalyzeReportView from '@/components/widgets/AnalyzeReportView';
import _menu from '@/_menu';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { version } from '@/config';
import { mapGetters } from 'vuex';
export default {
  name: 'workreportuserdaybasedtotaltime',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    PageInfo,
    AnalyzeReportView,
  },
  props: {},
  data () {
    return {
      d_loading: {
        'status': false,
      },
      d_reportTypeList: [],
      d_reports: { 'parameters': {}, 'paramorder': [] },
      d_pageInfoData: '',
      d_pageName: 'workreportuserdaybasedtotaltime',
      d_version: version,
      d_sideBarMenuList: _menu.website_data.page_list,
    };
  },
  created: function () {
    this.f_getReports();
  },
  beforeMount () {},
  mounted: function () {
    let is_page_found = false;
    for (let i in this.d_sideBarMenuList) {
      if (this.d_sideBarMenuList[i].route_name === this.d_pageName) {
        this.d_pageInfoData = this.d_sideBarMenuList[i];
        is_page_found = true;
        break;
      } else {
        if (this.d_sideBarMenuList[i].sub_menu && this.d_sideBarMenuList[i].sub_menu.length > 0) {
          for (let sub_i in this.d_sideBarMenuList[i].sub_menu) {
            if (this.d_sideBarMenuList[i].sub_menu[sub_i].route_name === this.d_pageName) {
              this.d_pageInfoData = this.d_sideBarMenuList[i].sub_menu[sub_i];
              is_page_found = true;
              break;
            }
          }
        }
      }
      if (is_page_found) {
        break;
      }
    }
  },
  methods: {
    f_getReports: function () {
      this.d_loading.status = true;
      let data = {
        'report_type_list': [
          'last_one_month_day_based_work_time',
        ]
      };
      OrganizationService.report_get(data)
        .then(resp => {
          this.d_loading.status = false;
          if (resp.data.status_code === '12000') {
            this.d_reports = resp.data.list;
          } else {
            console.log('error :' + resp.data.message);
          }
        });
    }
  },
  watch: {}
}

</script>


